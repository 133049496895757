<tc-form-wrapper
  [tcFormTitle]="'aggregation_entry/work.add' | translate"
  [isModal]="isModal"
  [isSubmitting]="isSubmitting"
  [isBatch]="isBatch"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  [tcExtended]="tcIsExtended"
  (tcExtendedChange)="onExtendedChange($event)"
  (primaryButtonClick)="onSave()"
>
  <button tcFormExtras nz-button type="button" (click)="openBatchCreateModal()">
    {{ 'aggregation_entry/work.batch.create' | translate }}
  </button>
  <form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    (ngSubmit)="onSave()"
    [ngClass]="{ 'tc-grid tc-grid-2-col': useColumns }"
  >
    <input type="submit" class="hidden" value="" />
    <tc-input-number-web
      class="tc-col-4"
      [tcCanDisable]="isBatch"
      label="{{ 'aggregation_entry/work.attrs.count' | translate }}"
      formControlName="count"
    ></tc-input-number-web>

    <tc-hub-collection-select
      class="tc-col-4"
      [tcCanDisable]="isBatch"
      label="{{ 'aggregation_entry/work.attrs.sku_id' | translate }}"
      #jobSelect
      [collectionTypes]="['Job']"
      [formGroup]="formGroup"
      controlName="sku_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-input-text-web
      class="tc-col-4"
      [tcCanDisable]="isBatch"
      label="{{ 'aggregation_entry/work.attrs.title' | translate }}"
      formControlName="title"
    ></tc-input-text-web>

    <tc-input-date-web
      class="tc-col-12"
      [tcCanDisable]="isBatch"
      (disabledToggle)="toggleTimes($event)"
      formControlName="date"
      [label]="'dispo/task.attrs.date' | translate"
      [tcConfig]="{ required: true }"
    ></tc-input-date-web>

    <tc-interval-set-web
      class="tc-col-12"
      *ngIf="!isBatch || formGroup.controls.times.enabled"
      formControlName="times"
      [tcReferenceDate]="formGroup.value.date"
      [tcConfig]="{
        required: true
      }"
    ></tc-interval-set-web>

    <tc-hub-custom-fields
      class="tc-col-12"
      *ngIf="!isBatch"
      section="work_entry.general"
      formControlName="workEntryGeneral"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      class="tc-col-12"
      *ngIf="!isBatch"
      section="work_entry.{{ tcAggregationType.handle }}.general"
      formControlName="workEntryHandleGeneral"
    >
    </tc-hub-custom-fields>

    <ng-container *ngIf="tcIsExtended">
      <tc-hub-collection-select
        *ngIf="!isBatch"
        [tcCanDisable]="isBatch"
        label="{{ 'aggregation_entry/work.attrs.resource_id' | translate }}"
        [collectionTypes]="['Employee', 'Contractor']"
        [formGroup]="formGroup"
        controlName="resource"
        searchable="true"
        bindLabel="name"
      >
      </tc-hub-collection-select>

      <tc-hub-collection-select
        [tcCanDisable]="isBatch"
        label="{{ 'aggregation_entry/work.attrs.group_id' | translate }}"
        [collectionTypes]="['DispoGroup']"
        [formGroup]="formGroup"
        controlName="group_id"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      >
      </tc-hub-collection-select>

      <tc-hub-collection-select
        [tcCanDisable]="isBatch"
        label="{{
          'aggregation_entry/work.attrs.qualification_ids' | translate
        }}"
        [collectionTypes]="['Qualification']"
        [formGroup]="formGroup"
        controlName="qualification_ids"
        bindLabel="title"
        [multiple]="true"
        bindValue="id"
        [searchable]="true"
      >
      </tc-hub-collection-select>
      <tc-hub-collection-select
        [tcCanDisable]="isBatch"
        label="{{ 'aggregation_entry/work.attrs.role_ids' | translate }}"
        [collectionTypes]="['DispoRole']"
        [formGroup]="formGroup"
        controlName="role_ids"
        [multiple]="true"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      >
      </tc-hub-collection-select>

      <tc-input-text-web
        *ngIf="!isBatch"
        label="{{ 'aggregation_entry/work.attrs.unit' | translate }}"
        formControlName="unit"
      ></tc-input-text-web>

      <tc-hub-collection-select
        [tcCanDisable]="isBatch"
        label="{{ 'dispo/task.attrs.venue' | translate }}"
        [collectionTypes]="['Venue']"
        [formGroup]="formGroup"
        controlName="venue_id"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      >
      </tc-hub-collection-select>

      <div *ngIf="!isBatch" class="tc-col-12 form-description">
        <label>{{ 'timesheet.attrs.description' | translate }} </label>
        <textarea formControlName="description" msd-elastic></textarea>
      </div>

      <div class="tc-col-12 gratis-checkbox">
        <tc-form-field-web
          [tcCanDisable]="isBatch"
          [disabled]="formGroup.get('gratis').disabled"
          (disabledChange)="onGratisDisabledChange($event)"
        >
          <label
            nz-checkbox
            formControlName="gratis"
            [nzDisabled]="gratisDisabled"
          >
            {{ 'aggregation_entry/work.attrs.gratis' | translate }}
          </label>
        </tc-form-field-web>
      </div>
    </ng-container>
  </form>
</tc-form-wrapper>
