<tc-form-wrapper
  [tcFormTitle]="'timesheet.singular' | translate"
  [isModal]="isModal"
  [isLoading]="!formGroup"
  [isSubmitting]="isSubmitting"
  [isInvalid]="formGroup?.invalid"
  [tcExtended]="tcIsExtended"
  (tcExtendedChange)="onExtendedChange($event)"
  (primaryButtonClick)="onSave()"
>
  <form
    class="form"
    *ngIf="formGroup"
    [formGroup]="formGroup"
    [ngClass]="{ 'tc-grid tc-grid-2-col': useColumns }"
    (ngSubmit)="onSave()"
  >
    <input type="submit" class="hidden" value="" />
    <tc-hub-collection-select
      label="{{ 'timesheet.attrs.resource_id' | translate }}"
      [collectionTypes]="resourceTypes"
      [formGroup]="formGroup"
      controlName="resources"
      bindLabel="name"
      [bindValue]="isModal ? 'id' : null"
      [multiple]="!isModal"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-hub-option-select
      label="{{ 'timesheet.attrs.state' | translate }}"
      type="timesheet.state"
      [formGroup]="formGroup"
      controlName="state"
      bindLabel="title"
      bindValue="id"
    >
    </tc-hub-option-select>

    <tc-hub-collection-select
      label="{{ 'dispo/task.attrs.job' | translate }}"
      #jobSelect
      [collectionTypes]="['Job']"
      [formGroup]="formGroup"
      controlName="job_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-hub-collection-select
      label="{{ 'dispo/task.attrs.venue' | translate }}"
      [collectionTypes]="['Venue']"
      [formGroup]="formGroup"
      controlName="venue_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-interval-set-web
      class="tc-col-12"
      formControlName="times"
      [tcShowDateInput]="true"
      [tcConfig]="{
        required: true
      }"
    ></tc-interval-set-web>

    <tc-hub-custom-fields
      class="tc-col-12 custom-fields"
      section="timesheet.general"
      [formGroup]="formGroup"
      formControlName="timesheetGeneral"
    >
    </tc-hub-custom-fields>

    <ng-container *ngIf="tcIsExtended">
      <div class="tc-col-12 form-description">
        <label>{{ 'timesheet.attrs.description' | translate }} </label>
        <textarea formControlName="description" msd-elastic></textarea>
      </div>
      <div class="tc-col-12 checkbox-wrapper">
        <div class="gratis-checkbox">
          <label nz-checkbox formControlName="gratis">
            {{ 'timesheet.attrs.gratis' | translate }}
          </label>
        </div>

        <label class="pay-grade-checkbox">
          <nz-switch formControlName="custom_pay_grade"></nz-switch>
          {{ 'timesheet.attrs.custom_pay_grade' | translate }}
        </label>
      </div>
      <div *ngIf="formGroup.value.custom_pay_grade">
        <tc-hub-collection-select
          class="tc-col-12 custom-pay-grade"
          label="{{ 'timesheet.attrs.tariff_id' | translate }}"
          [collectionTypes]="['Tariff']"
          [formGroup]="formGroup"
          controlName="tariff_id"
          bindLabel="title"
          bindValue="id"
          [searchable]="true"
        >
        </tc-hub-collection-select>

        <tc-hub-remote-select
          class="tc-col-12 custom-pay-grade"
          label="{{ 'timesheet.attrs.pay_grade_id' | translate }}"
          [url]="payGradeUrl$ | async"
          [formGroup]="formGroup"
          controlName="pay_grade_id"
          bindLabel="title"
          bindValue="id"
          searchable="true"
        >
        </tc-hub-remote-select>
      </div>

      <div class="form-item full-width supplements">
        <tc-input-number-web
          *ngFor="let supplement of supplements"
          [label]="supplement.display_key"
          [tcConfig]="{ step: '0.01' }"
          formControlName="supplement_{{ supplement.id }}"
        >
        </tc-input-number-web>
      </div>

      <tc-hub-custom-fields
        class="tc-col-12 custom-fields"
        section="timesheet.extended"
        formControlName="timesheetExtended"
      >
      </tc-hub-custom-fields>
    </ng-container>
  </form>
</tc-form-wrapper>
